import React, { Component } from "react";
import "./AndMore.css";

export default class AndMore extends Component {
    render() {
        return (
            <div className="container AndMore">
                <hr />
                <span>and more</span>
            </div>
        );
    }
}
